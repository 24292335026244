import React from "react"
import styled from "styled-components";

const BlogVideoSection = (props) => {
    const { videoMedia } = props;

    let videoUrl = videoMedia?.url
    if (videoUrl?.includes('watch?v=') && !videoUrl?.includes('/embed/')) {
        videoUrl = videoUrl?.replace('watch?v=', 'embed/')
    }
    return (
        <BlogVideoSectionStyled>
            {videoMedia &&
                <div className="flex flex-column blog-kuda-section--max mx-auto">
                    <div className="kuda-section--inner flex justify-between align-center">
                        <div className="blog-video-container mx-auto">
                            <iframe
                                width="848"
                                height="400"
                                src={videoUrl}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            }
        </BlogVideoSectionStyled>
    )
}

export const BlogVideoSectionStyled = styled.div`
 margin: 1rem 0rem;
video {
    object-fit: contain;
    overflow-clip-margin: content-box;
    overflow: clip;
    width: 100%;
}
.blog-video-container {
    margin-top: 20px;
    width: 100%;
    height: 330px;
    min-height: 100%;
}

.blog-video-container iframe {
    width: 100%;
    height: 100%;
    min-height: 0px;
}
   
@media only screen and (max-width: 1024px){
    .blog-video-container{
        height: 285px;
    }
}
@media only screen and (max-width: 768px){
    .blog-video-container{
        height: 230px;
    }
}
@media only screen and (max-width: 425px){
    .blog-video-container{
        height: 180px;
    }
}
@media only screen and (max-width: 350px){
    .blog-video-container{
        height: 100%;
    }
}
`

export default BlogVideoSection;
